import Metadata from "components/utils/metadata/metadata"
import React from "react"

const NotFoundPage = ({ location }) => {
  return (
    <>
      {/* eslint-disable react/jsx-pascal-case */}
      <Metadata title="404: Not found" pathname={location.pathname} />

      <h1>NOT FOUND</h1>
      <p>We're sorry but the page you're looking for can't be found.</p>
    </>
  )
}

export default NotFoundPage
